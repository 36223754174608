import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestmanagerService } from '../../utils/restmanagerService';
import { Speciality } from '../../_models/admin/speciality'

@Injectable({ providedIn: 'root' })

export class specialitylistService {

  private restmanagerService: RestmanagerService;

  constructor(restmanagerService: RestmanagerService, private _http: HttpClient) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  //Get speciality list api
  getspecialityList() {
    return this.restmanagerService.getnew<Speciality>('Speciality/GetSpecialityList');
  }
  _apiEndPoint: string = '';

  //Add speciality
  addSpeciality<T>(apiURL: string, Name: string, Symptomps: any, SpcialityFees: any, Description: any, specialityDoctorSplit: any, SpecialityAdminSplit: any): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {

        Name,
        Symptomps,
        SpcialityFees,
        Description,
        specialityDoctorSplit,
        SpecialityAdminSplit
      })
    );
  }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  //Upadate speciality api
  UpdateSpeciality(specialityId, name: any, symptomps: any, spcialityFees: any, Description: any, specialityDoctorSplit: any, SpecialityAdminSplit: any) {
    return this._http.post<Speciality>(`${environment.apiUrl}/speciality/UpdateSpeciality`, {
      specialityId,
      name,
      symptomps,
      spcialityFees,
      Description,
      specialityDoctorSplit,
      SpecialityAdminSplit
    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Delete speciality api
  deleteSpeciality<T>(apiURL: string, specialityId: string): Observable<T[]> {
    return this.mapAndCatchError(
      this._http.post<T[]>(`${this._apiEndPoint}/${apiURL}`, {
        specialityId
      })
    );
  }
}
